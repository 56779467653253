<template>
  <div>
    <h3 class="text-center">Edit Opportunities</h3>

    <div class="margin-20-bottom">
      <b-form-input
        placeholder="Position"
        v-model="careers.position"
      ></b-form-input>
      <p v-if="careersError.position" class="text-center" style="color: red">
        Careers Title Can't be empty
      </p>
    </div>

     <quill-editor v-model="careers.job_description"
                ref="myQuillEditor"
                :options="editorOption"
                >
  </quill-editor>
    <p
      v-if="careersError.job_description"
      class="text-center"
      style="color: red"
    >
      Careers job description Can't be empty
    </p>

   
    <br />
    <br />
      <label for="header">Location</label>
    <b-input v-model="careers.location"> </b-input>
    <p class="text-center" style="color: red" v-if="careersError.location">
      location can't be empity
    </p>
    <br>
     
       <div >
    <label for="example-datepicker">Choose a deadline</label>
    
  
  <b-input-group class="mb-3">
      <b-form-input
        id="example-input"
        v-model="careers.actualDate"
        type="text"
        placeholder="YYYY-MM-DD"
        autocomplete="off"
      ></b-form-input>
      <b-input-group-append>
        <b-form-datepicker
          v-model="careers.actualDate"
          button-only
          right
          locale="en-US"
          aria-controls="example-input"
          @context="onContext"
        ></b-form-datepicker>
      </b-input-group-append>
  </b-input-group>
          </div>
          <br>

    <span>
      <label for="header">employment_type</label>
      <b-form-select
        v-model="careers.employment_type"
        :options="employmentType"
      ></b-form-select>
    </span>
    <br />

   
    <label for="header">Job Requirement</label>
    
 
    <quill-editor v-model="careers.job_requirement"
                ref="myQuillEditor"
                :options="editorOption"
                >
  </quill-editor>
      
      <br />
    

    <p
      class="text-center"
      style="color: red"
      v-if="careersError.job_requirement"
    >
      Job Requirement can't be empty
    </p>

    <div class="horizontal-center">
      <b-button variant="success" @click="addcareers"><b-spinner v-if="loading"  small></b-spinner>Submit</b-button>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import {  pathFieldWithOutId} from "../../assets/js/service";
import ImagPicker from "../Common/ImageUpload.vue";
export default {
  components: {
    ImagPicker,
  },
    watch:{
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
  },
  data() {
    return {
      careers: {
        position: null,
        
        employment_type: "permanent",
      
        job_description: null, 
        job_requirement: [""],
      },
      careersError: {
        position: null,
       
        employment_type: null,
       
        job_description: null,
        job_requirement: null,
      },
      detail: null,
      linkText: null,
      link: null,
      loading: false,
      employmentType: [
        
        { value: "Contract", text: "Contract" },
        { value: "Temporary", text: "Temporary" },
         { value: "Full-time", text: "Full-time" },
        { value: "Part-time", text: "Part-time" },
         { value: "Full-time", text: "Full-time" },
        { value: "Part-time", text: "Part-time" },
        { value: "Volunteer", text: "Volunteer" },
        { value: "Internship", text: "Internship" },
      
      ],
    };
     
  },
  mounted() {
     if(this.$store.state.singlePageData){
        this.careers = this.$store.state.singlePageData;
        }else{
          this.$router.go(-1)
        }
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string

      this.careers.deadline = ctx.selectedFormatted;

      // The following will be an empty string until a valid date is entered
    },
    changeHtmlElement() {
      detail.split("");
    },
    addLink() {
      if (this.link && this.linkText) {
        this.careers.detail =
          this.careers.detail +
          '<a href="' +
          this.link +
          '">' +
          this.linkText +
          "</a>";
      }
    },
    addcareers() {
      if (!this.careers.position) {
        this.careersError.position = true;
      } else {
        this.careersError.position = false;
      }
     
      
      if (!this.careers.employment_type) {
        this.careersError.employment_type = true;
      } else {
        this.careersError.employment_type = false;
      }
     
      if (!this.careers.job_description) {
        this.careersError.job_description = true;
      } else {
        this.careersError.job_description = false;
      }
      

      if (!this.careers.job_requirement) {
        this.careersError.job_requirement = true;
      } else {
        
        this.careersError.job_requirement = false;
      }
      if (
        this.careers.position &&
      
        this.careers.employment_type &&
        
        this.careers.job_description &&
        this.careers.job_requirement
      ) {
        let token = localStorage.getItem("token");
        this.$store.commit("set", ["loading", true]);
        pathFieldWithOutId("careers", this.careers, token).then((resp) => {
          this.careers = resp.data;
          this.$store.commit("set", ["loading", false]);
          Vue.$toast.success("Successful", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
      }
    },
  },
};
</script>
<style>
</style>